<template>
    <form>
        <b-overlay :show="loading" class="p-1" rounded>
            <div class="row mb-4">
                <div class="col-lg-3">
                    <label
                        :class="{'text-danger': states.path === false}"
                        class="form-label"
                        for="path"
                    >{{ $t('menus::menu_points.columns.path').ucFirst() }}</label
                    >
                    <div class="col-lg-10">
                        <select
                            id="path"
                            v-model="model.path"
                            :class="{'border-danger': states.path === false}"
                            class="form-select"
                            name="path"
                        >
                            <option :value="model.id ? null : undefined">
                                {{ $t('menus::menu_points.placeholders.path').ucFirst() }}
                            </option>
                            <option v-for="(route, key) in routes" :key="key" :value="route.path">
                                {{ route.path }}
                                <template v-if="$te('routes.' + route.path)">
                                    ({{ $t('routes.' + route.path) }})
                                </template>
                            </option>
                        </select>
                        <div :class="{'d-block': states.path === false}" class="invalid-feedback">{{
                                errors.path
                            }}
                        </div>
                    </div>
                </div>
                <div class="col-lg-3">
                    <label
                        :class="{'text-danger': states.name === false}"
                        class="form-label"
                        for="name"
                    >{{ $t('menus::menu_points.columns.name').ucFirst() }}</label
                    >
                    <div class="col-lg-10">
                        <input
                            id="name"
                            v-model="model.name"
                            :class="{'border-danger': states.name === false}"
                            :placeholder="$t('menus::menu_points.placeholders.name').ucFirst()"
                            class="form-control"
                            name="name"
                            type="text"
                        />
                        <div :class="{'d-block': states.name === false}" class="invalid-feedback">{{
                                errors.name
                            }}
                        </div>
                    </div>
                </div>
                <div class="col-lg-3">
                    <label
                        :class="{'text-danger': states.translation_key === false}"
                        class="form-label"
                        for="translation-key"
                    >{{ $t('menus::menu_points.columns.translation_key').ucFirst() }}</label
                    >
                    <div class="col-lg-10">
                        <translation-input
                            v-model="model.translation_key"
                            :error="errors.translation_key"
                            :placeholder="$t('menus::menu_points.placeholders.translation_key').ucFirst()"
                            :state="states.translation_key"
                        >
                        </translation-input>
                    </div>
                </div>
                <div class="col-lg-3">
                    <label
                        :class="{'text-danger': states.icon === false}"
                        class="form-label"
                        for="translation-key"
                    >{{ $t('menus::menu_points.columns.icon').ucFirst() }}</label
                    >
                    <div class="col-lg-10">
                        <b-input-group>
                            <b-input-group-text class="cursor-pointer p-0">
                                <icon-input
                                    v-model="model.icon"
                                    :error="errors.icon"
                                    :placeholder="$t('menus::menu_points.placeholders.icon').ucFirst()"
                                    :state="states.icon"
                                    class="cursor-pointer"
                                >
                                </icon-input>
                            </b-input-group-text>
                            <input :value="model.icon" class="form-control" readonly type="text">
                            <b-input-group-text v-if="model.icon" class="p-0 bg-white">
                                <i :class="model.icon" class="py-1 px-3"></i>
                            </b-input-group-text>
                        </b-input-group>
                    </div>
                </div>
                <div class="col-lg-3">
                    <label
                        class="col-lg-2 form-label"
                        for="authentication_required"
                    >{{ $t('menus::menu_points.columns.authentication_required').ucFirst() }}</label
                    >
                    <div class="col-lg-10">
                        <div class="form-check form-switch form-switch-md mb-3 ps-0">
                            <input id="authentication_required" v-model="model.authentication_required"
                                   class="form-check-input float-none mx-3" type="checkbox">
                        </div>
                    </div>
                </div>
                <div class="col-lg-3">
                    <label
                        :class="{'text-danger': states.description === false}"
                        class="form-label"
                        for="description"
                    >{{ $t('menus::menu_points.columns.description').ucFirst() }}</label
                    >
                    <div class="col-lg-10">
                  <textarea
                      id="description"
                      v-model="model.description"
                      :class="{'border-danger': states.description === false}"
                      :placeholder="$t('menus::menu_points.placeholders.description').ucFirst()"
                      class="form-control"
                      rows="3"
                  ></textarea>
                        <div :class="{'d-block': states.description === false}" class="invalid-feedback">
                            {{ errors.description }}
                        </div>
                    </div>
                </div>
                <div class="col-lg-3">
                    <label
                        :class="{'text-danger': states.menu_id === false}"
                        class="form-label"
                        for="menu-id"
                    >{{ $t('menus::menu_points.columns.menu_id').ucFirst() }}</label
                    >
                    <div class="col-lg-10">
                        <select
                            id="menu-id"
                            v-model="model.menu_id"
                            :class="{'border-danger': states.menu_id === false}"
                            class="form-select"
                            name="menu_id"
                        >
                            <option :value="model.id ? null : undefined">
                                {{ $t('menus::menu_points.placeholders.menu_id').ucFirst() }}
                            </option>
                            <option v-for="(menu, key) in menus" :key="key" :value="menu.id">
                                {{ menu.translation_key ? $t(menu.translation_key).ucFirst() : menu.name }}
                            </option>
                        </select>
                        <div :class="{'d-block': states.menu_id === false}" class="invalid-feedback">{{
                                errors.menu_id
                            }}
                        </div>
                    </div>
                </div>
                <div class="col-lg-3">
                    <label
                        :class="{'text-danger': states.menu_point_category_id === false}"
                        class="form-label"
                        for="menu-point-category-id"
                    >{{ $t('menus::menu_points.columns.menu_point_category_id').ucFirst() }}</label
                    >
                    <div class="col-lg-10">
                        <select
                            id="menu-point-category-id"
                            v-model="model.menu_point_category_id"
                            :class="{'border-danger': states.menu_point_category_id === false}"
                            class="form-select"
                            name="menu_point_category_id"
                        >
                            <option :value="model.id ? null : undefined">
                                {{ $t('menus::menu_points.placeholders.menu_point_category_id').ucFirst() }}
                            </option>
                            <option v-for="(menuPointCategory, key) in menuPointCategories" :key="key"
                                    :value="menuPointCategory.id">{{
                                    menuPointCategory.translation_key ? $t(menuPointCategory.translation_key).ucFirst() : menuPointCategory.name
                                }}
                            </option>
                        </select>
                        <div :class="{'d-block': states.menu_point_category_id === false}" class="invalid-feedback">
                            {{ errors.menu_point_category_id }}
                        </div>
                    </div>
                </div>
                <div class="col-lg-3">
                    <label
                        :class="{'text-danger': states.menu_point_id === false}"
                        class="form-label"
                        for="menu-point-id"
                    >{{ $t('menus::menu_points.columns.menu_point_id').ucFirst() }}</label
                    >
                    <div class="col-lg-10">
                        <select
                            id="menu-point-id"
                            v-model="model.menu_point_id"
                            :class="{'border-danger': states.menu_point_id === false}"
                            class="form-select"
                            name="menu_point_id"
                        >
                            <option :value="model.id ? null : undefined">
                                {{ $t('menus::menu_points.placeholders.menu_point_id').ucFirst() }}
                            </option>
                            <option v-for="(menuPoint, key) in menuPoints" :key="key" :value="menuPoint.id">{{
                                    menuPoint.translation_key ? $t(menuPoint.translation_key).ucFirst() : menuPoint.name
                                }}
                            </option>
                        </select>
                        <div :class="{'d-block': states.menu_point_id === false}" class="invalid-feedback">
                            {{ errors.menu_point_id }}
                        </div>
                    </div>
                </div>
            </div>
            <div class="row justify-content-end">
                <div class="col-lg-10">
                    <button class="btn btn-primary" type="submit" @click.prevent="save">
                        {{ btnText }}
                    </button>
                    <slot name="buttons" :save="save"></slot>
                </div>
            </div>
        </b-overlay>
    </form>
</template>

<script>
import {mapGetters} from "vuex";
import IconInput from "../elements/icon-input.vue";
import TranslationInput from "../elements/translation-input.vue";

export default {
    name: "MenuPointForm",

    emits: ['saved'],

    components: {TranslationInput, IconInput},

    props: {
        menuPoint: {
            type: Object,
            required: true
        }
    },

    computed: {
        ...mapGetters({
            'loading': 'menuPoint/loading',
            'states': 'menuPoint/states',
            'errors': 'menuPoint/errors',
            'translations': 'trans/keys',
            'menus': 'menu/all',
            'menuPoints': 'menuPoint/all',
            'menuPointCategories': 'menuPointCategory/all',
        }),

        btnText: function () {
            return this.model.id ? this.$t('base.save').ucFirst() : this.$t('base.create').ucFirst()
        },

        routes: function () {
            return this.$router.getRoutes().filter(item => item.aliasOf === undefined);
        },
    },

    data: function () {
        return {
            model: JSON.parse(JSON.stringify(this.menuPoint)),
        }
    },

    methods: {
        save: function () {
            const method = this.model.id ? 'update' : 'create'

            return this.$store.dispatch(`menuPoint/${method}`, this.model).then(saved => {
                this.model = JSON.parse(JSON.stringify(saved))
                this.$emit('saved')
                if (method === 'create') {
                    this.$router.push(this.$t('routes.' + '/menu-system/menu-points/:id(\\d+)', {id: this.model.id}).rmValidation())
                }
            })
        },
    },

    watch: {
        menuPoint: function () {
            this.model = JSON.parse(JSON.stringify(this.menuPoint))
        },
    },

    created() {
        // console.log(this.menuPoint)
    }
}
</script>

<style scoped>

</style>
